import React from "react";
import Footer from "../components/footer";
import { Card, CardGroup, Container, Row, Col, Table } from "react-bootstrap";
import App from "../containers/App";
import "./calendario-academico.css";

const calendar_acad = () => (
  <App>
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginBottom: "50px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col md={{ span: 12 }} style={{ marginTop: "30px" }}>
          <p className="eventos-titulo">CALENDÁRIO ACADÊMICO </p>
         </Col>
      </Row>
    </Container>
	
    <div
      class="calendario-academico"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: "2px",
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        backgroundColor: "#F4F4F4",
      }}
    >
      <p style={{padding: 0, margin: 0 }}>
        <iframe
          src="https://sjc.fatec.sp.gov.br/downloads/calendario/calendario_2025-1.pdf" title="calendario"
          frameborder="0"
          style={{
            width: "100%",
            height: "1500px",
          }}
        ></iframe>
      </p>
	  
	 </div>
	 
	 <Footer />
  </App>
);

export default calendar_acad;
